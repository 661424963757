// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-tsx": () => import("./../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-ai-strategy-tsx": () => import("./../src/pages/ai-strategy.tsx" /* webpackChunkName: "component---src-pages-ai-strategy-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-clients-tsx": () => import("./../src/pages/clients.tsx" /* webpackChunkName: "component---src-pages-clients-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-custom-ai-tsx": () => import("./../src/pages/custom-ai.tsx" /* webpackChunkName: "component---src-pages-custom-ai-tsx" */),
  "component---src-pages-delivery-tsx": () => import("./../src/pages/delivery.tsx" /* webpackChunkName: "component---src-pages-delivery-tsx" */),
  "component---src-pages-eu-fund-tsx": () => import("./../src/pages/eu-fund.tsx" /* webpackChunkName: "component---src-pages-eu-fund-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-solveengine-tsx": () => import("./../src/pages/solveengine.tsx" /* webpackChunkName: "component---src-pages-solveengine-tsx" */),
  "component---src-pages-workforce-tsx": () => import("./../src/pages/workforce.tsx" /* webpackChunkName: "component---src-pages-workforce-tsx" */),
  "component---src-templates-case-study-tsx": () => import("./../src/templates/case-study.tsx" /* webpackChunkName: "component---src-templates-case-study-tsx" */)
}

