/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
const SmoothScroll = require("smooth-scroll")
const transitionDelay = 1000
const aos = require("aos")
exports.shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  if (location.action === "PUSH") {
    window.scrollTo(0, 0)
  } else {
    const savedPosition = getSavedScrollPosition(location)
    window.setTimeout(
      () => window.scrollTo(...(savedPosition || [0, 0])),
      transitionDelay,
    )
  }
  return false
}
exports.onRouteUpdate = ({ location }) => {
  aos.init({
    once: true,
    duration: 600,
    offset: -window.innerHeight * 0.2,
  })
}
